<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import apiTool from '@/command/apiTool'
import { hotelStarRating, hotelType, brandOwnership } from '@/utils/textFile'

export default {
  name: 'hotelHomestayManageDetail',
  data() {
    return {
      // 区域类型 省市区
      areaCode: [],
      ...api.command.getState(),
      detail: {
        starLevelName: '',
        brandOfOwnershipName: '',
        checkInTime: '14:00',
        checkOutTime: '12:00',
        receptionInstructions: '接待来自任何国家/地区的客人',
        priceDescription:
          '页面展示的酒店预订价格会根据市场情况(房型剩余、时间等) 随时发生变化，请您具体以订单页面成交价格为准。',
        bookingPrompt:
          '订单需要等酒店或服务提供方确认后生效，订单确认结果以服务提供方短信为准;请在订单生效后再至酒店前台办理入住',
        paymentInstructions:
          '本产品为向酒店或代理商申请的特殊价格产品，无法保证预定成功；订单提交将立即扣款，若预定不成功，房费将原路退还至付款账户中',
        telephoneButton: '0',
      },
      storeData: [],
      labelGroup: [],
      star_hotel_arr: [],
      brand_arr: [],
      jwdAyy: [],
    }
  },

  mounted() {
    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaCode',
    })

    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/hotel/detail?id=${id}`,
        })
        .then((res) => {
          this.storeSearch(res.linkShopName)

          this.detail.areaCode = [res.provinceCode, res.cityCode, res.areaCode]
          this.detail.dw = `${res.lon}，${res.lat}`
        })
    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'farmhouse_public',
          },
        ],
      },
    })

    this.$forceUpdate()
  },
  methods: {
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value || ''}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        value: this.jwdAyy,
        id: this.$route.query.id,
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          let index = this.jwdAyy.findIndex((e) => e.id == data.id)
          let icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            imageSize: new AMap.Size(20, 40),
          })
          getAction('/api/gao', {
            location: `${this.detail.lon},${this.detail.lat}`,
          }).then((res) => {
            let { address, codes } = res.data
            this.detail.areaCode = codes
            this.detail.address = address
            this.$forceUpdate()
          })
          let obj = {
            lng: data.lng,
            lat: data.lat,
            icon: icon,
            id: data.id,
          }
          if (index > -1) {
            this.jwdAyy.splice(index, 1, obj)
          } else {
            this.jwdAyy.push(obj)
          }
          this.$forceUpdate()
        },
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'name',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShopName',
            onInputSearch: this.storeSearch,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '简写（对于度假村可以将简称加上，很多用户知道简称）',
            type: 'input',
            cols: 12,
            key: 'nameAd',
          },
          {
            name: '类型',
            type: 'select',
            cols: 12,
            key: 'hotelType',
            typeData: hotelType(),
            onChange: (val) => {
              this.detail.hotelTypeName = hotelType().find((f) => f.value === val)?.name || ''
            },
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择星级',
              },
            ],
          },
          {
            name: '星级',
            type: 'select',
            cols: 12,
            key: 'starLevel',
            typeData: hotelStarRating(),
            onChange: (val) => {
              this.detail.starLevelName = hotelStarRating().find((f) => f.value === val)?.name || ''
            },
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择星级',
              },
            ],
          },
          {
            name: '所属品牌',
            type: 'select',
            cols: 12,
            key: 'brandOfOwnership',
            typeData: brandOwnership(),
            onChange: (val) => {
              this.detail.brandOfOwnershipName = brandOwnership().find((f) => f.value === val)?.name || ''
            },
          },

          {
            name: '经纬度',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                disabled: true,
                placeholder: '请定位所在位置',
                rules: [
                  {
                    required: true,
                    type: 'string',
                    message: '请定位所在位置',
                  },
                ],
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            type: 'cascader',
            cols: 6,
            key: 'areaCode',
            name: '所在城市',
            placeholder: '请选择地址',
            typeData: this.areaCode,
            rules: [
              {
                required: true,
                type: 'array',
                message: '请选择地址',
              },
            ],
          },
          //   {
          //     name: '所在城市（直接输入城市名称或输入拼音下拉选择）',
          //     type: 'dropDownInput',
          //     cols: 12,
          //     key: 'mainBodyName',
          //     onInputSearch: this.storeSearch,
          //     valueKey: 'mainBodyId',
          //     inputProps: {
          //       placeholder: '搜索店铺',
          //       addonBefore: <a-icon type="search" />
          //     },
          //     rules: [
          //       {
          //         required: true
          //       }
          //     ]
          //   },
          {
            name: '详细地址',
            type: 'input',
            cols: 6,
            key: 'address',
            rules: [
              {
                required: true,
              },
            ],
          },

          {
            type: 'switch',
            key: 'telephoneButton',
            name: '酒店电话开关',
            cols: 3,
          },
          {
            name: '酒店电话',
            type: 'input',
            cols: 9,
            key: 'hotelPhone',
          },
          {
            name: '客服手机号',
            slotTitle: () => {
              return (
                <span>
                  客服手机号<span style={{ marginLeft: '5px', color: '#e5e5e5' }}>(接收确认订单短信)</span>
                </span>
              )
            },
            type: 'input',
            cols: 12,
            key: 'customerPhone',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: 'switch',
            key: 'scoreShowType',
            name: '开启自定义评分',
            cols: 3,
            reversal: true,
          },
          {
            name: '评分',
            slotTitle: () => {
              return (
                <span>
                  评分<span style={{ marginLeft: '5px', color: '#e5e5e5' }}>(不填默认用真实评分)</span>
                </span>
              )
            },
            type: 'input',
            cols: 9,
            key: 'modifScore',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '入住时间',
            type: 'timePicker',
            cols: 6,
            key: 'checkInTime',
            props: {
              format: 'HH:mm',
              valueFormat: 'HH:mm',
              allowClear: false,
            },
          },
          {
            name: '离店时间',
            type: 'timePicker',
            cols: 6,
            key: 'checkOutTime',
            props: {
              format: 'HH:mm',
              valueFormat: 'HH:mm',
              allowClear: false,
            },
          },
        ],
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制10张
          </div>
        ),
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            typeData: [
              {
                multiple: true,
                maxLength: 10,
                key: 'bannerUrl',
                desc: '<br /> 支持格式.png/.jpg',
              },
            ],
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
        {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl',
        },
      ].filter((e) => e)
    },
    getForm3() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        cols: 24,
        showCard: false,
        data: [
          {
            type: 'rich',
            key: 'richText',
            cols: 24,
          },
        ],
      }
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.areaCode[i]
      })
      return obj
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            let codeData = this.getSumbitData()
            // 获取标签
            let arr = []
            this.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            this.labelGroup.map((e) => arr.push(...e.list))
            let brr = arr.map((e) => ({
              ...e,
              czState: e.czState ? 1 : 0,
            }))

            let upDown = this.detail.id ? this.detail.upDown : '0'

            let params = {
              ...data,
              ...codeData,
              farmLabelDataDTOS: brr,
              upDown,
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: `/hotel/${this.detail.id ? 'update' : 'save'}`,
                params,
                isPost: false,
              })
              .then((result) => {
                this.$router.push('/commodityManagement/hotelHomestayManage')
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/hotelHomestayManage')
          },
        },
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          display: this.detail.id,
          onClick: () => {
            getAction(`/api/hotel/${this.detail.upDown == 0 ? 'downBatch' : 'upBatch'}`, {
              idList: [this.detail.id],
            }).then((e) => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.push('/commodityManagement/hotelHomestayManage')
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          display: this.detail.id,
          onClick: () => {
            api.command.del.call(this, {
              url: `/hotel/deleteBatch`,
              params: {
                idList: [this.detail.id],
              },
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/commodityManagement/hotelHomestayManage')
            }, 500)
          },
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    getForm5() {
      return {
        type: 'cardForm',
        cols: 24,
        title: '视频',
        data: [
          {
            type: 'uploadVideo',
            key: 'videoUrl',
            cols: 24,
          },
        ],
      }
    },
    getForm6() {
      return {
        type: 'cardForm',
        title: 'VR',
        data: [
          {
            type: 'input',
            name: 'VR链接',
            key: 'vrUrl',
            maxLength: 1000,
          },
        ],
      }
    },
    getForm7() {
      return {
        type: 'cardForm',
        title: '酒店政策',
        data: [
          {
            type: 'textArea',
            name: '接收说明',
            cols: 12,
            key: 'receptionInstructions',
            placeholder: '接待来自任何国家/地区的客人',
          },
          {
            type: 'textArea',
            name: '价格说明',
            cols: 12,
            key: 'priceDescription',
            placeholder:
              '页面展示的酒店预订价格会根据市场情况(房型剩余、时间等) 随时发生变化，请您具体以订单页面成交价格为准。',
          },
          {
            type: 'textArea',
            name: '预定提示',
            cols: 12,
            key: 'bookingPrompt',
            placeholder:
              '订单需要等酒店或服务提供方确认后生效，订单确认结果以服务提供方短信为准;请在订单生效后再至酒店前台办理入住',
          },
          {
            type: 'textArea',
            name: '在线支付',
            cols: 12,
            key: 'paymentInstructions',
            placeholder:
              '本产品为向酒店或代理商申请的特殊价格产品，无法保证预定成功；订单提交将立即扣款，若预定不成功，房费将原路退还至付款账户中',
          },
        ],
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        typeData={{
          linkShopName: this.storeData,
        }}
        data={[
          this.getForm1(),
          this.getForm2(),
          this.getForm5(),
          this.getForm6(),
          this.getForm7(),
          this.getForm3(),
          this.getForm4(),
        ]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
